import { Property } from 'csstype'

import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import { CarouselButtonPlacement, Font } from '@thg-commerce/gravity-patterns'
import { ChevronIconStyle } from '@thg-commerce/gravity-patterns/Carousel/theme'
import { IconStyling } from '@thg-commerce/gravity-patterns/Header/theme'
import { BorderBox } from '@thg-commerce/gravity-patterns/Header/types'
import {
  BreakpointArray,
  Direction,
  Order,
  SpacingBox,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import { Border } from '@thg-commerce/gravity-theme/border'
import { ShadowInterface } from '@thg-commerce/gravity-theme/elements/button'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

import { createPageLayoutTheme } from '../pageLayout'
import { Component, PageLayout, PartialPageLayout } from '../Renderer/layout'

export enum ProductSummaryPriceDisplayStyle {
  CHEAPEST = 'cheapest',
  RANGE = 'range',
}

export enum ProductImageGalleryStyle {
  CAROUSEL = 'carousel',
  GRID = 'grid',
}

export enum OutOfStockSuccessMessageVariant {
  DEFAULT = 'default',
  ALTERNATE = 'alternate',
}

export enum PromotionalMessagePriority {
  HIGH = 'high',
  LOW = 'low',
}

export enum ProductSummaryOrder {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
}

interface ImageGallery extends Component<'imageGallery'> {
  style: BreakpointArray<ProductImageGalleryStyle>
  urgencyMessagingVerticalPlacementMobile?: number
  grid?: {
    // Indicates if the `style` contains a grid style at any breakpoint
    enabled: boolean
    gapSpacing: number
    imageAspectRatio?: string
    urgencyMessagingVerticalPlacement?: number
  }
  carousel: {
    // Indicates if the `style` contains a carousel style at any breakpoint
    showFooter: BreakpointArray<boolean>
    enabled: boolean
    gapSpacing: BreakpointArray<number>
    gutterMargin?: BreakpointArray<number>
    itemsPerSlide: BreakpointArray<number>
    direction: BreakpointArray<Direction>
    itemGapSpacing?: number
    controls?: {
      visible?: BreakpointArray<boolean>
      placement?: CarouselButtonPlacement
      size?: number
      chevronIconStyle?: ChevronIconStyle
      border?: string
      background?: string
      controlBorderColour?: Property.Color
      controlHoverBackgroundColor?: Property.Color
    }
    thumbnails?: {
      marginXSpacing?: BreakpointArray<number>
    }
    overflow?: BreakpointArray<string>
    urgencyMessagingVerticalPlacement?: number
  }
  tagStyle?: {
    container?: {
      padding?: Padding
      width?: BreakpointArray<string>
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
  }
}
interface ProductInfoBoxes extends Component<'productInfoBoxes'> {
  icons: {
    delivery: {
      svgPath: string
    }
    clickAndCollect: {
      svgPath: string
    }
    returns: {
      svgPath: string
    }
    width: string
    height: string
    viewBox: string
  }
}

interface DeliveryInformation extends Component<'deliveryInformation'> {
  icons: {
    width: string
    height: string
    viewBox: string
    iconDefinitions: Record<string, { svgPath: string }>
  }
}

interface ProductSummary extends Component<'productSummary'> {
  price: {
    displayStyle: ProductSummaryPriceDisplayStyle
    displayRRPText: boolean
    direction: BreakpointArray<Direction>
    price: {
      order?: Order
      textStyle: TextStyling
    }
    rrp: {
      order?: Order
      textStyle: TextStyling
      background?: string
    }
    savings: {
      show: boolean
      textStyle: TextStyling
      label: {
        show: boolean
      }
      background?: string
    }
    savingsPercentage?: {
      show: boolean
    }
    priceWithRRP: {
      textStyle: TextStyling
    }
    discountMessage?: {
      textStyle?: TextStyling
    }
  }
  title?: {
    textStyle: TextStyling
    order?: ProductSummaryOrder
    margin?: Margin
  }
  subtitle?: {
    textStyle?: TextStyling
    order?: ProductSummaryOrder
    margin?: Margin
  }
  tags?: {
    order?: ProductSummaryOrder
    colors?: {
      text?: Property.Color
      tag?: Property.Color
    }
    textStyle?: TextStyling
    padding?: string
  }
  externalIdentifierDisplay?: {
    order?: ProductSummaryOrder
  }
  productClearance?: {
    order?: ProductSummaryOrder
  }
  proofOfImpactIcon?: {
    order?: ProductSummaryOrder
  }
  displaySeparator: boolean
  separatorMargin?: {
    top?: number
    bottom?: number
  }
  loyaltyPointsIcon?: IconStyling
  margin?: Margin
}

interface ShoeSizeMe extends Component<'shoeSizeMe'> {}

interface SizeGuide extends Component<'sizeGuide'> {
  modalLink?: {
    textStyle: TextStyling
  }
  modal?: {
    margin?: Margin
    height?: string
  }
  table?: {
    head?: {
      backgroundColor?: string
      color?: string
    }
    caption?: {
      font: TextStyling
    }
    margin?: Margin
  }
  title?: TextStyling
}

interface IconInterface {
  fill: Property.Color
  opacity: number
  color: Property.Color
}

export interface QubitBadge extends Component<'marketingBadgeStyle'> {
  border?: BorderBox
  container?: {
    position: Property.Position
    left: string
    transform: string
    marginLeft: {
      desktop: string
      mobile: string
    }
    verticalAlignmentTop: string
    width: string
  }
  font?: TextStyling
  boxShadow?: ShadowInterface
  height?: string
  padding?: SpacingBox
}

interface ButtonStyleInterface {
  icon: IconInterface
  button: {
    borderColor: Property.Color
    backgroundColor: Property.Color
    backgroundOpacity: number
  }
}

export interface WishlistButtonStyleInterface {
  default: ButtonStyleInterface
  hover: ButtonStyleInterface
  active: ButtonStyleInterface
  focus: ButtonStyleInterface
}

export interface WishlistButtonPDPStyle {
  margin: Margin
}

export interface OutOfStockButtonStyleInterface {
  hover: {
    textDecoration: string
  }
  disabled: {
    cursor: string
    borderWidth: string
    borderColor: string
  }
}

export interface ProductOptions extends Component<'productOptions'> {
  quantitySelector: {
    quantityLabelText?: {
      quantityLabel: {
        textStyle: {
          entry: TextEntry
          style: TextStyle
        }
      }
    }
    margin: {
      top: string
      right: string
      bottom: string
      left: string
    }
  }
  order?: number[]
  addToBasketContainer: {
    direction: 'row' | 'column'
    fullWidth: boolean
    addToWishlistButton: {
      show: boolean
      style?: WishlistButtonStyleInterface
    }
    outOfStockButton?: {
      style: OutOfStockButtonStyleInterface
    }
  }
  deliveryFulfilmentTextStyle?: {
    firstLine: {
      textStyle: {
        entry: TextEntry
        style: TextStyle
      }
    }
  }
  addToWishlistButton: {
    show: boolean
    style?: WishlistButtonPDPStyle
  }
  personalisationFontsStyleOverride?: {
    show: boolean
  }
  scrollToTopButton?: {
    textStyle: TextStyling
    show: boolean
  }
  swatch?: {
    show: boolean
  }
  showOutOfStockAsDisabled: boolean
  variantSelector?: {
    textStyle: {
      textTransform: string
    }
  }
}
interface FreeDeliveryIndicator extends Component<'freeDeliveryIndicator'> {
  margin?: Margin
}

interface AddToBasket extends Component<'addToBasket'> {
  addToBasketButton: {
    emphasis: ButtonEmphases
  }
}

interface BuyNowPayLater extends Component<'buyNowPayLater'> {
  title: {
    show: boolean
  }
  price: {
    font: Font
  }
  readMoreLink: {
    font: Font
  }
  backgroundColor?: Property.Color
  borderRadius?: Property.BorderRadius
}

interface ProductAvailability extends Component<'productAvailability'> {}
interface OutOfStockNotification extends Component<'outOfStockNotification'> {
  subtitle: {
    show: boolean
  }
  footerMessaging: {
    show: boolean
  }
  contentWrapper?: {
    padding?: string
    backgroundColor?: string
  }
  container: {
    margin: {
      top: string
      right: string
      bottom: string
      left: string
    }
  }
  heading: {
    margin: {
      bottom: string
    }
  }
  checkbox: {
    transparentBorder: boolean
    container: {
      padding: {
        top: string
        right: string
        bottom: string
        left: string
      }
    }
    label?: {
      padding: string
    }
  }
  emailField?: {
    margin: {
      top: string
    }
  }
  submitButton?: {
    sm: {
      width: string
    }
  }
  successMessage: {
    variant: OutOfStockSuccessMessageVariant
  }
  separator: {
    show: boolean
  }
}

interface MarketedSpecialOffer extends Component<'marketedSpecialOffer'> {
  badge?: {
    icon?: IconStyling
    textStyle?: Font
    padding?: Padding
    border?: { width: string; type: string }
    margin?: Margin
    textAlign?: string
    borderRadius?: string
  }
  containerStyle?: {
    border?: {
      width: string
      type: string
    }
    margin?: Margin
  }
  modal?: {
    display?: string
    textAlign?: string
    maxWidth?: string
    margin?: Margin
    descriptionColorText?: Property.Color
  }
}

interface PromotionalMessage extends Component<'promotionalMessage'> {
  priority?: PromotionalMessagePriority
  warningKeywords?: string[]
  promotionalMessageBackground?: {
    backgroundColor?: string
  }
  warningStyles?: {
    backgroundColor?: string
    border?: Border
  }
}
interface DeliveryAndReturnsInfo extends Component<'deliveryAndReturnsInfo'> {
  fontWeight: { entry: TextEntry; style: TextStyle }
  returnsTitleTextStyle?: { entry: TextEntry; style: TextStyle }
}

interface ButtonStateStyleInterface {
  button: {
    backgroundColor: string
    border: {
      width: string
      color: string
    }
    text: {
      style: TextStyle
      color: string
      textDecoration: string
    }
    boxShadow: {
      shadowX: string
      shadowY: string
      shadowBlur: string
      shadowSpread: string
      shadowColor: string
    }
  }
}
export interface StartLiveChatCTAStyleInterface {
  default: ButtonStateStyleInterface
  hover: ButtonStateStyleInterface
  focus: ButtonStateStyleInterface
  active: ButtonStateStyleInterface
}
interface LiveChat extends Component<'liveChat'> {
  topContainerStyleOverride?: {
    backgroundColor?: string
  }
  liveChatTextEntry?: { entry: TextEntry }
  onlineTextStyle?: { style?: TextStyle }
  startChatCTAStyle?: StartLiveChatCTAStyleInterface
  chatIconStyleOverride?: {
    width?: string
    height?: string
    backgroundSize?: string
    backgroundPosition?: string
    margin?: string
  }
  iconContainerStyleOverride?: {
    width?: string
    height?: string
    color?: Property.Color
  }
  containerStyleOverride?: {
    border?: string
    backgroundColor?: string
    boxShadow?: string
  }
}
interface ProductDescription extends Component<'productDescription'> {
  accordion: {
    colSpan?: number
    colStart?: number
    hideTitle?: boolean
    title: {
      textStyling: TextStyling
      padding: Padding
    }
    content: {
      padding: SpacingBox
    }
  }
}
interface FrequentlyBoughtTogether
  extends Component<'frequentlyBoughtTogether'> {}
interface StoryStream extends Component<'storyStream'> {}
interface OtherCustomersBought extends Component<'otherCustomersBought'> {}
interface ProductRecommendations extends Component<'productRecommendations'> {}
interface TopProductReviews extends Component<'topProductReviews'> {
  reviewTitle?: {
    textAlignment?: string
    textStyle?: TextStyling
    textTransform?: string
  }
  topReviewTitle?: {
    textStyle: TextStyling
  }
  noReviewTitle?: {
    textStyle: TextStyling
  }
  individualReviewPaddingTop?: number
}
interface RecentlyViewedProducts extends Component<'recentlyViewedProducts'> {
  container?: {
    gridGap: string
  }
}
interface ManualRecommendation extends Component<'manualRecommendation'> {
  price?: {
    direction: BreakpointArray<Direction>
  }
  swatch?: {
    show: boolean
  }
}
interface ProductRating extends Component<'productRating'> {
  filter: {
    enabled: boolean
    colorFills?: {
      active: Property.Color
      inactive: Property.Color
      hover: Property.Color
    }
  }
  tag?: {
    colorFills: {
      primary: Property.Color
      secondary: Property.Color
    }
  }
  bar: {
    colorFills: {
      fill: Property.Color
      background: Property.Color
    }
  }
  individualStar: {
    colorFills: {
      fill: Property.Color
      background: Property.Color
    }
  }
  averageStars: {
    colorFills: {
      fill: Property.Color
      background: Property.Color
    }
    font?: {
      entry: TextEntry
    }
  }
  starNumber: {
    textStyle: TextStyling
  }
  reviewsCount: {
    textStyle: TextStyling
  }
  totalReviews: {
    textStyle: TextStyling
  }
  CTAs?: {
    createReviewCTA?: {
      emphasis: ButtonEmphases
      border?: Property.Color
    }
    viewAllReviewsCTA?: {
      emphasis: ButtonEmphases
      border?: Property.Color
    }
  }
  inStockText?: {
    textStyle: TextStyling
  }
}
interface ProductReviewContent extends Component<'productReviewContent'> {
  title?: {
    textStyle: TextStyling
  }
  star: {
    colorFills: {
      fill: Property.Color
      background: Property.Color
    }
  }
  thumbsIcon?: {
    dislike?: {
      svgPath?: string
    }
    like?: {
      svgPath?: string
    }
    viewBox?: string
    width?: string
    height?: string
  }
  footer?: {
    textStyle: TextStyling
  }
}

export interface PurchaseOptions extends Component<'purchaseOptions'> {
  subscribeAndGainIcon?: {
    path: string
    width: string
    height: string
    viewBox: string
    styling: {
      fill: Property.Color
      hover: {
        fill: Property.Color
      }
      focus: {
        fill: Property.Color
      }
    }
  }
  tabList?: {
    backgroundColor: Property.Color
    width: string
    margin?: {
      bottom: number
    }
    button: {
      hover: {
        backgroundColor?: Property.Color
        color: Property.Color
      }
      focus?: {
        backgroundColor?: Property.Color
        border: string
      }
    }
  }
  tabContainer?: {
    width: string
  }
}

export interface ProductPersonalisation
  extends Component<'productPersonalisation'> {
  layout: string[]
  showClassicContent: Boolean
}

interface ProductStockInformation extends Component<'productStockInformation'> {
  message: {
    textTransform: string
  }
}

export interface ProductPageLayout
  extends PageLayout<
    | ImageGallery
    | ProductSummary
    | ShoeSizeMe
    | SizeGuide
    | ProductOptions
    | FreeDeliveryIndicator
    | BuyNowPayLater
    | ProductAvailability
    | OutOfStockNotification
    | MarketedSpecialOffer
    | PromotionalMessage
    | DeliveryAndReturnsInfo
    | LiveChat
    | ProductDescription
    | FrequentlyBoughtTogether
    | StoryStream
    | OtherCustomersBought
    | ProductRecommendations
    | TopProductReviews
    | RecentlyViewedProducts
    | ManualRecommendation
    | ProductRating
    | ProductReviewContent
    | ProductInfoBoxes
    | PurchaseOptions
    | QubitBadge
    | ProductPersonalisation
    | ProductStockInformation
    | AddToBasket
    | DeliveryInformation
  > {}

export type PartialProductPageLayout = PartialPageLayout<ProductPageLayout>

const { styled, useTheme } = createPageLayoutTheme<ProductPageLayout>()

export { styled, useTheme }
